import mitt from "mitt"
import { some } from "lodash-es"

const ALLOWED_DUPLICATE_HANDLER_EVENTS = ["click-modal-body"]

class Bus {
  constructor() {
    this.bus = mitt()
    this.eventsMap = this.bus.all
  }

  on(eventName, handler) {
    if (!this.isHandlerExists(eventName, handler) || ALLOWED_DUPLICATE_HANDLER_EVENTS.includes(eventName)) {
      this.bus.on(eventName, handler)
    }
  }

  off(eventName, handler) {
    this.bus.off(eventName, handler)
  }

  emit(eventName, eventData) {
    this.bus.emit(eventName, eventData)
  }

  isHandlerExists(eventName, handler) {
    const existingHandlers = this.getEventHandlers(eventName)

    return some(existingHandlers, existingHandler => {
      return existingHandler.toString() === handler.toString()
    })
  }

  getEventHandlers(eventName) {
    return this.eventsMap.get(eventName)
  }
}

export default new Bus()
