import multiguard from "vue-router-multiguard"
import { authenticated, authorized } from "@/config/router/guards"
import { authorizedPriceManagement } from "./helpers"

// export const generalSettingsChildren = [
//   {
//     name: "ModeSettings",
//     path: "mode-settings",
//     component: () =>
//       import(
//         /* webpackChunkName: "GeneralSettings_ModeSettings" */ "@/pages/PriceManagement/GeneralSettings/ModeSettings"
//       ),
//     meta: {
//       title: "general_settings",
//       permissionType: "mode_settings"
//     }
//   },
//   {
//     name: "CompetitorsSettings",
//     path: "competitors-settings",
//     component: () =>
//       import(
//         /* webpackChunkName: "GeneralSettings_CompetitorsSettings" */ "@/pages/PriceManagement/GeneralSettings/CompetitorsSettings"
//       ),
//     meta: {
//       title: "general_settings",
//       permissionType: "competitors_settings"
//     }
//   },
//   {
//     name: "RuleSettings",
//     path: "rule-settings",
//     component: () =>
//       import(
//         /* webpackChunkName: "GeneralSettings_RuleSettings" */ "@/pages/PriceManagement/GeneralSettings/RuleSettings"
//       ),
//     meta: {
//       title: "general_settings",
//       permissionType: "rule_settings"
//     }
//   }
// ]

export default [
  {
    path: "/prices/price-calendar",
    name: "PriceCalendar",
    component: () => import(/* webpackChunkName: "Prices_PriceCalendar" */ "@/pages/PriceManagement/PriceCalendar"),
    beforeEnter: multiguard([authenticated, authorized, authorizedPriceManagement]),
    meta: {
      title: "price_calendar",
      permissionType: "price_calendar"
    }
  },
  {
    path: "/prices/competitor-settings",
    name: "GeneralSettings",
    component: () =>
      import(
        /* webpackChunkName: "Prices_GeneralSettings" */ "@/pages/PriceManagement/GeneralSettings/CompetitorsSettings"
      ),
    beforeEnter: multiguard([authenticated, authorized, authorizedPriceManagement]),
    meta: {
      title: "general_settings",
      permissionType: "competitors_settings"
    }
  },
  {
    path: "/prices/plan-settings",
    name: "PlanSettings",
    redirect: { name: "BasicSettings" },
    component: () => import(/* webpackChunkName: "Prices_PlanSettings" */ "@/pages/PriceManagement/PlanSettings"),
    beforeEnter: multiguard([authenticated, authorized, authorizedPriceManagement]),
    meta: {
      title: "plan_settings",
      permissionType: "plan_settings"
    },
    children: [
      {
        name: "BasicSettings",
        path: "basic-settings",
        component: () =>
          import(/* webpackChunkName: "Prices_BasicSettings" */ "@/pages/PriceManagement/PlanSettings/BasicSettings"),
        meta: {
          title: "plan_settings",
          permissionType: "plan_settings"
        }
      },
      {
        name: "ByPeriod",
        path: "by-period",
        component: () =>
          import(/* webpackChunkName: "Prices_ByPeriod" */ "@/pages/PriceManagement/PlanSettings/ByPeriod"),
        meta: {
          title: "plan_settings",
          permissionType: "plan_settings"
        }
      }
    ]
  },
  {
    path: "/prices/competitor-prices",
    name: "CompetitorPrices",
    component: () => import(/* Prices_CompetitorPrices */ "@/pages/PriceManagement/CompetitorPrices"),
    beforeEnter: multiguard([authenticated, authorized, authorizedPriceManagement]),
    meta: {
      title: "competitor_prices",
      permissionType: "competitor_prices"
    }
  }
]
