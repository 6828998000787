import { isEmpty } from "lodash-es"
import { store } from "@/config"
import { getAccessToken, clearAuthCookies, isLoggedIn } from "@/helpers/auth"
import { hasPermission, isSuperadmin, isOrganizationAdmin, isOtaAdmin } from "@/helpers/permission"
import { PERMISSION_DENY } from "@/config/constants"
import { GUARD_ROUTES } from "@/config/router/constants"
import { isSharedInventoryEnabled } from "@/helpers/organization"

const {
  INVENTORY_PAGE,
  SHARED_INVENTORY_PAGE,
  MAESTRO_ACCOUNT_LIST_PAGE,
  ORGANIZATION_ACCOUNT_LIST_PAGE,
  COMPANY_LIST_PAGE,
  FORBIDDEN_PAGE,
  SIGN_IN_PAGE
} = GUARD_ROUTES

export const authenticated = async (_to, _from, next) => {
  if (isEmpty(getAccessToken())) return next(SIGN_IN_PAGE)

  try {
    await store.dispatch("FETCH_CURRENT_USER")
    if (isLoggedIn()) {
      await store.dispatch("FETCH_PAGES")
      next()
    } else {
      clearAuthCookies()
      next(SIGN_IN_PAGE)
    }
  } catch (e) {
    next()
  }
}

export const anonymous = async (_to, _from, next) => {
  if (isEmpty(getAccessToken())) return next()

  try {
    await store.dispatch("FETCH_CURRENT_USER")
    if (isLoggedIn()) {
      handleRootPath(undefined, undefined, next)
    } else {
      next()
    }
  } catch (e) {
    next()
  }
}

export const handleRootPath = (_to, _from, next) => {
  if (isSuperadmin()) {
    next(MAESTRO_ACCOUNT_LIST_PAGE)
  } else if (isOrganizationAdmin()) {
    next(ORGANIZATION_ACCOUNT_LIST_PAGE)
  } else if (isOtaAdmin()) {
    next(COMPANY_LIST_PAGE)
  } else {
    next(INVENTORY_PAGE)
  }
}

export const authorized = ({ meta: { permissionType } }, _from, next) => {
  if (hasPermission(permissionType, PERMISSION_DENY)) {
    next(FORBIDDEN_PAGE)
  } else {
    deniedSuperadmin(null, null, next)
  }
}

export const setLayout = ({ matched }, _from, next) => {
  if (matched.length !== 0) {
    const layout = matched[0].components.default.layout

    if (store.state.layout !== layout) {
      store.commit("SET_LAYOUT", layout)
    }
  }
  next()
}

export const deniedSuperadmin = (_to, _from, next) => {
  if (isSuperadmin()) {
    next(MAESTRO_ACCOUNT_LIST_PAGE)
  } else {
    next()
  }
}

export const onlySuperadmin = (_to, _from, next) => {
  if (isSuperadmin()) {
    next()
  } else {
    next(FORBIDDEN_PAGE)
  }
}

export const onlyOtaAdmin = (_to, _from, next) => {
  if (isOtaAdmin()) {
    next()
  } else {
    next(FORBIDDEN_PAGE)
  }
}

export const onlyOrganizationAdmin = (_to, _from, next) => {
  if (isOrganizationAdmin()) {
    next()
  } else {
    next(FORBIDDEN_PAGE)
  }
}

export const inventoryPage = (to, _from, next) => {
  const nextTo = isSharedInventoryEnabled() ? SHARED_INVENTORY_PAGE : INVENTORY_PAGE

  if (to.name === nextTo.name) {
    next()
  } else {
    next(nextTo)
  }
}

export const inventoryMode = ({ shared }) => (_to, _from, next) => {
  if ((shared && isSharedInventoryEnabled()) || (!shared && !isSharedInventoryEnabled())) {
    next()
  } else {
    next(FORBIDDEN_PAGE)
  }
}
