import multiguard from "vue-router-multiguard"
import { anonymous } from "@/config/router/guards"

export default [
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import(/* webpackChunkName: "SignIn" */ "@/pages/Authentication/SignIn"),
    beforeEnter: multiguard([anonymous])
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: () => import(/* webpackChunkName: "SetPassword" */ "@/pages/Authentication/SetPassword"),
    beforeEnter: multiguard([anonymous])
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "ResetPassword" */ "@/pages/Authentication/ResetPassword"),
    beforeEnter: multiguard([anonymous])
  }
]
