import { i18n, router } from "@/config"
import { flow } from "lodash-es"
import { getAuthHeaders, setAuthCookies, getAccessToken } from "@/helpers/auth"

import { signOutOnError, honeybadgerRequestNotify, redirectOnError, showErrorToast } from "./errorHandlers"

const localeRequestInterceptor = config => {
  config.headers.common = {
    ...config.headers.common,
    locale: i18n.locale
  }
  return config
}

const pageRequestInterceptor = config => {
  const { currentPage } = config.headers.common

  config.headers.common = {
    ...config.headers.common,
    currentPage: currentPage || router.currentRoute.meta.permissionType || "sign_in"
  }
  return config
}

const authRequestInterceptor = config => {
  config.headers.common = {
    ...config.headers.common,
    ...getAuthHeaders()
  }
  return config
}

const authResponseInterceptor = response => {
  setCookiesRequestHeaders(response.headers)

  return response
}

export const errorInterceptor = error => {
  setCookiesRequestHeaders(error.response.headers)

  const { skipErrorToast } = error.config
  const shouldNotify = !signOutOnError(error) && !redirectOnError(error)

  if (shouldNotify && process.env.VUE_APP_HONEYBADGER_ENABLED === "true") honeybadgerRequestNotify(error)
  if (!skipErrorToast) showErrorToast(error)

  return Promise.reject(error)
}

const setCookiesRequestHeaders = headers => {
  const { client, uid, expiry, "token-type": tokenType } = headers
  const accessToken = headers["access-token"] || getAccessToken()
  if (client) {
    setAuthCookies({ client, accessToken, uid, tokenType, expiry })
  }
}

export const requestInterceptor = flow([authRequestInterceptor, localeRequestInterceptor, pageRequestInterceptor])
export const responseInterceptor = flow([authResponseInterceptor])
