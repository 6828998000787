<template lang="pug">
  #app
    component(:is="layoutComponent")
    AppModal
    AppNotify
    AppConfirm
</template>

<script>
  // misc
  import { isEmpty } from "lodash-es"

  export default {
    components: {
      default: () => import("@/components/layout/default"),
      simple: () => import("@/components/layout/simple"),
      auth: () => import("@/components/layout/auth"),
      AppModal: () => import("@/components/elements/AppModal"),
      AppConfirm: () => import("@/components/elements/AppConfirm"),
      AppNotify: () => import("@/components/elements/AppConfirm/Notify")
    },

    computed: {
      layoutComponent() {
        return this.$store.state.layout
      }
    },

    // idle-vue adds onIdle() hook to handle the reaching of idle time
    onIdle() {
      if (!isEmpty(this.currentUser)) {
        this.$store.dispatch("FETCH_CURRENT_USER")
      }
    }
  }
</script>
