import Vue from "vue"
import { i18n } from "@/config"

export const showToast = ({ text, title = i18n.t("error"), variant = "danger", autoHide = true, hideDelay = 5000 }) => {
  const vm = new Vue()
  vm.$bvToast.toast(text, {
    noAutoHide: !autoHide,
    variant,
    title,
    autoHideDelay: hideDelay
  })
}
