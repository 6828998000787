import { debounce, camelCase, upperFirst, forEach, has, reduce, map } from "lodash-es"
import { convertToTimeZone } from "date-fns-timezone"
import { DEFAULT_TIMEZONE, DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { i18n } from "@/config"
import { showToast } from "@/helpers/toasts"

import { format as dateFormat } from "date-fns"

export const randomString = () => (Math.random() + 1).toString(36).substring(2)

export const extractTranslatedAttribute = (item, attribute = "text") => {
  const currentLocale = i18n.getLocale()
  const key = `${currentLocale}_${attribute}`
  return item[key]
}

export const pascalCase = string => {
  return upperFirst(camelCase(string))
}

export const confirmAction = text => {
  return new Promise((resolve, reject) => {
    if (confirm(text)) {
      resolve()
    } else {
      reject()
    }
  })
}

export const currentDateInTimeZone = () => convertToTimeZone(new Date(), { timeZone: DEFAULT_TIMEZONE })

export const translateOtaName = otaName => i18n.t(`ota.${otaName}`)

export const translateOtaList = otas =>
  map(otas, ota => ({ ...ota, rawName: ota.name, name: translateOtaName(ota.name) }))

export const appDebounce = (func, wait = 1000, options) => debounce(func, wait, options)

export const showMultipleErrors = (error, key) => {
  const response = error.response.data
  if (has(response, key)) {
    forEach(error.response.data[key], text => {
      showToast({ text })
    })
  } else {
    handleFailedResponse(error)
  }
}

export const resetValidatorValues = (validator, properties = []) => {
  properties.forEach(prop => {
    if (validator[prop]) {
      validator[prop].$model = null
    }
  })
}

export const handleFailedResponse = ({ response }) => {
  const errorData = response.data?.record
  const text = errorData ? Object.values(errorData).flat() : i18n.t("error")
  showToast({ text })
}

export const create12HourClock = ({ value, abbr }) => {
  const finalAbbr = abbr.toLowerCase().trim()
  if (finalAbbr == "am") {
    return i18n.t("time.am", { value })
  } else if (finalAbbr == "pm") {
    return i18n.t("time.pm", { value })
  }
}

export const toFormattedDate = value => dateFormat(value, DATE_FNS_DATE_FORMAT)

export const toObjectBy = (field, collection) => {
  return reduce(
    collection,
    (obj, item) => {
      obj[item[field]] = item
      return obj
    },
    {}
  )
}

export const randomKey = () => {
  const arr = new Uint8Array((8 || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join("")
}

export const isEnvironment = type => process.env.VUE_APP_ENV === type
