import Vue from "vue"
import Honeybadger from "@honeybadger-io/vue"

import { some } from "lodash-es"

if (process.env.VUE_APP_HONEYBADGER_ENABLED === "true") {
  const config = {
    environment: process.env.VUE_APP_ENV,
    apiKey: process.env.VUE_APP_HONEYBADGER_API_KEY,
    revision: process.env.VUE_APP_ENV,
    enableUnhandledRejection: false,
    async: true
  }

  Vue.use(Honeybadger, config)

  const calculateFingerprint = ({ environment, name, message }) => `${environment}_${name}_${message}`
  const isNoticeSendable = notice => !some(ERRORS_TO_SKIP, skip => notice.message.match(skip)?.length > 0)

  Vue.$honeybadger.beforeNotify(notice => {
    notice.fingerprint = calculateFingerprint(notice)

    // TRUE => send error to HB, FALSE => don't send
    return isNoticeSendable(notice) && ["staging", "production"].includes(process.env.VUE_APP_ENV)
  })

  const ERRORS_TO_SKIP = [/UnhandledPromiseRejectionWarning/, /ResizeObserver/, /Недопустимый знак/]

  Vue.config.errorHandler = function(error, { _uid, $data, $props }, info) {
    const context = {
      vm: { _uid, $data, $props },
      info
    }

    Vue.$honeybadger.notify(error, { context, tags: "common" })
  }
}
