import Vue from "vue"
import i18n from "@/config/i18n"
import { get } from "lodash-es"
import { clearAuthCookies } from "@/helpers/auth"
import { router, store } from "@/config"
import { MAIL_SUPPORT } from "@/config/constants"
import { showToast } from "@/helpers/toasts"

const ERRORS_WITH_TOAST = [400, 500, 501, 502, 503, 504, 505]
const ERRORS_REDIRECT = { 404: "NotFound" }
const ERRORS_HONEYBADGER_SKIP = [401, 403, 404, 422]

export const signOutOnError = ({ response }) => {
  const currentRouteName = router.currentRoute.name

  if (response.status === 401 && currentRouteName != "SignIn") {
    clearAuthCookies()
    if (currentRouteName !== null) {
      Vue.cookie.set("lastUserRouteBeforeSignOut", currentRouteName)
    }
    router.replace({ name: "SignIn" })

    return true
  }

  return false
}

export const showErrorToast = ({ response }) => {
  if (ERRORS_WITH_TOAST.includes(response.status)) {
    showToast({
      text: i18n.t("requests.unexpected_failure", { email: MAIL_SUPPORT })
    })
  }
}

export const redirectOnError = ({ response, config }) => {
  const route = ERRORS_REDIRECT[response.status]

  if (config.errorRedirect && route) {
    router.replace({ name: route })
    return true
  }
  return false
}

export const honeybadgerRequestNotify = error => {
  if (!ERRORS_HONEYBADGER_SKIP.includes(error.response.status)) {
    const { url, baseURL, headers, params, method } = error.config
    const message = `[${error.response.status}] Request to "${url}" failed`
    const context = {
      apiUrl: `${baseURL}${url}`,
      route: router.currentRoute.name,
      headers,
      method,
      currentUserId: get(store.state.currentUser, "id")
    }
    Vue.$honeybadger.notify(error, { context, params, message, tags: "RequestFailed" })
  }
}
