import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format as dateFormat,
  add as dateAdd,
  sub as dateSub,
  startOfDay,
  startOfMonth
} from "date-fns"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { map } from "lodash-es"
import { currentDateInTimeZone } from "@/helpers/common"

export const getDateRange = (startDate, endDate) => {
  return eachDayOfInterval({ start: new Date(startDate), end: new Date(endDate) })
}

export const getMonthRange = (startDate, endDate) => {
  return eachMonthOfInterval({ start: new Date(startDate), end: new Date(endDate) })
}

export const getFormattedDateRange = (startDate, endDate) => {
  const range = getDateRange(startDate, endDate)
  return map(range, date => dateFormat(date, DATE_FNS_DATE_FORMAT))
}

export const getFormattedDate = (dateString, daysToAdd = 0) => {
  const date = dateAdd(new Date(dateString), { days: daysToAdd })

  return dateFormat(date, DATE_FNS_DATE_FORMAT)
}

export const startOfCurrentDateInTimeZone = () => startOfDay(currentDateInTimeZone())
export const startOfCurrentMonthInTimeZone = () => startOfMonth(currentDateInTimeZone())
export const startOfPreviousMonthInTimeZone = () =>
  startOfDay(startOfMonth(dateSub(currentDateInTimeZone(), { months: 1 })))
export const in300DaysFromCurrentDate = () => dateAdd(startOfCurrentDateInTimeZone(), { days: 300 })
export const in180DaysFromCurrentDate = () => dateAdd(startOfCurrentDateInTimeZone(), { days: 180 })
