import multiguard from "vue-router-multiguard"
import { authenticated, authorized } from "@/config/router/guards"

export default [
  {
    path: "/permission-settings",
    name: "PermissionSettings",
    component: () =>
      import(/* webpackChunkName: "PermissionSettings" */ "@/pages/AccountManagement/PermissionSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "permission_settings",
      permissionType: "permission_settings"
    }
  },
  {
    path: "/account-list",
    name: "AccountList",
    component: () => import(/* webpackChunkName: "AccountList" */ "@/pages/AccountManagement/AccountList"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "account_list",
      permissionType: "account_list"
    }
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    component: () => import(/* webpackChunkName: "CreateAccount" */ "@/pages/AccountManagement/CreateAccount"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "create_account",
      permissionType: "create_account"
    }
  },
  {
    path: "/edit-account/:id(\\d+)",
    name: "EditAccount",
    component: () => import(/* webpackChunkName: "EditAccount" */ "@/pages/AccountManagement/EditAccount"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "edit_account",
      permissionType: "account_list"
    }
  },
  {
    path: "/edit-profile",
    name: "EditOwnAccount",
    component: () => import(/* webpackChunkName: "EditOwnAccount" */ "@/pages/AccountManagement/EditOwnAccount"),
    beforeEnter: multiguard([authenticated]),
    meta: {
      title: "edit_own_account",
      permissionType: "edit_own_account"
    }
  }
]
