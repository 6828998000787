import { generalSettingsChildren } from "./index"
import { find } from "lodash-es"
import { hasPermission } from "@/helpers/permission.js"
import { PERMISSION_DENY } from "@/config/constants"
import { isDisabledMode } from "@/helpers/price-calendar"
import { GUARD_ROUTES } from "@/config/router/constants"
import { store } from "@/config"

const { FORBIDDEN_PAGE } = GUARD_ROUTES

const requestedSubpageAllowed = ({ name }) => {
  const subpage = find(generalSettingsChildren, { name })
  return subpage && !hasPermission(subpage.meta.permissionType, PERMISSION_DENY)
}

export const generalSettingsRedirect = (to, _from, next) => {
  if (requestedSubpageAllowed(to)) {
    next()
  } else {
    const anyAllowedSubpage = find(generalSettingsChildren, ({ meta: { permissionType } }) => {
      return !hasPermission(permissionType, PERMISSION_DENY)
    })

    if (anyAllowedSubpage) {
      next({ name: anyAllowedSubpage.name })
    } else {
      next(FORBIDDEN_PAGE)
    }
  }
}

export const authorizedPriceManagement = (to, _from, next) => {
  const accessType = store.state.currentUser.permissions[to.meta.permissionType]
  if (isDisabledMode() || accessType === undefined || accessType === PERMISSION_DENY) {
    next(FORBIDDEN_PAGE)
  } else {
    next()
  }
}
