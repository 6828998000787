import multiguard from "vue-router-multiguard"
import { authenticated, authorized, handleRootPath, inventoryPage } from "@/config/router/guards"

export default [
  {
    path: "/",
    beforeEnter: multiguard([authenticated, handleRootPath])
  },
  {
    path: "/inventory",
    name: "InventoryManagement",
    component: () => import(/* webpackChunkName: "InventoryManagement" */ "@/pages/InventoryManagement"),
    beforeEnter: multiguard([authenticated, authorized, inventoryPage]),
    meta: {
      title: "inventory_management",
      permissionType: "inventory_management"
    }
  },
  {
    path: "/shared-inventory",
    name: "SharedInventoryManagement",
    component: () => import(/* webpackChunkName: "SharedInventoryManagement" */ "@/pages/SharedInventoryManagement"),
    beforeEnter: multiguard([authenticated, authorized, inventoryPage]),
    meta: {
      title: "shared_inventory_management",
      permissionType: "inventory_management"
    }
  },
  {
    path: "/sale-stop-settings",
    name: "SaleStopSettings",
    component: () => import(/* webpackChunkName: "SharedInventoryManagement" */ "@/pages/SaleStopSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "sale_stop_settings",
      permissionType: "inventory_management"
    }
  },
  {
    path: "/sold-out",
    name: "SoldOut",
    component: () => import(/* webpackChunkName: "SoldOut" */ "@/pages/SoldOut"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "sold_out",
      permissionType: "inventory_management"
    }
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: () => import(/* webpackChunkName: "Reservations" */ "@/pages/Reservations"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "reservations",
      permissionType: "reservations"
    }
  },
  {
    path: "/logs",
    name: "Logs",
    redirect: { name: "UserActionLog" },
    component: () => import(/* webpackChunkName: "Logs" */ "@/pages/Logs"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "logs",
      permissionType: "logs"
    },
    children: [
      {
        name: "UserActionLog",
        path: "user-action-log",
        component: () => import(/* webpackChunkName: "Logs_UserActionLog" */ "@/pages/Logs/UserActionLog"),
        meta: {
          title: "logs",
          permissionType: "logs"
        }
      },
      {
        name: "InventoryLog",
        path: "inventory-log",
        component: () => import(/* webpackChunkName: "Logs_InventoryLog" */ "@/pages/Logs/InventoryLog"),
        meta: {
          title: "logs",
          permissionType: "logs"
        }
      }
    ]
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import(/* webpackChunkName: "Notifications" */ "@/pages/Notifications"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "notifications",
      permissionType: "notifications"
    }
  }
]
