import { map, isObject, isString } from "lodash-es"

const extractFieldData = item => {
  if (isObject(item)) {
    return extractFromObject(item)
  } else if (isString(item)) {
    return extractFromString(item)
  }
}

const extractFromObject = fieldData => ({ field: fieldData.field, fieldData })

const extractFromString = field => ({ field, fieldData: { field } })

const fakeSort = () => {}

const i18nKey = (i18nPrefix, value) => `${i18nPrefix}.${value}`

export const configPrepare = (config, { i18nPrefix, defaults, backendSort = true }) => {
  return map(config, item => {
    const { field, fieldData } = extractFieldData(item)

    const config = {
      ...defaults,
      label: i18nKey(i18nPrefix, field),
      ...fieldData
    }

    if (backendSort) {
      config.sortFn = fakeSort
    }

    return config
  })
}

export const normalizeSortingParams = ({ field, type, direction }) => {
  if (type === "none" || direction === "none") return {}

  if (type) {
    return { field, direction: type }
  } else {
    return { field, direction }
  }
}
