import Vue from "vue"

import { store } from "@/config"
import { isEmpty } from "lodash-es"

window.VUE = Vue

export const clearAuthCookies = () => {
  Vue.cookie.delete("api.client")
  Vue.cookie.delete("api.accessToken")
  Vue.cookie.delete("api.uid")
  Vue.cookie.delete("api.tokenType")
  Vue.cookie.delete("api.expiry")
  Vue.cookie.delete("fingerprint")
}

export const getAuthHeaders = () => {
  return {
    client: Vue.cookie.get("api.client"),
    uid: Vue.cookie.get("api.uid"),
    fingerprint: Vue.cookie.get("fingerprint"),
    "access-token": getAccessToken(),
    "token-type": Vue.cookie.get("api.tokenType")
  }
}

export const getAccessToken = () => {
  return Vue.cookie.get("api.accessToken")
}

export const setAuthCookies = ({ client, accessToken, uid, tokenType, expiry }) => {
  Vue.cookie.set("api.client", client)
  Vue.cookie.set("api.accessToken", accessToken)
  Vue.cookie.set("api.uid", uid)
  Vue.cookie.set("api.tokenType", tokenType)
  Vue.cookie.set("api.expiry", expiry)
}

export const isLoggedIn = () => {
  return !isEmpty(store.state.currentUser)
}
