import authRoutes from "./auth-routes"
import baseRoutes from "./base-routes"
import priceManagementRoutes from "./price-management-routes"
import settingsRoutes from "./settings-routes"
import accountManagementRoutes from "./account-management-routes"
import miscRoutes from "./misc-routes"
import planManagementRoutes from "./plan-management-routes"
import superadminRoutes from "./superadmin-routes"
import otaAdminRoutes from "./ota-admin-routes"
import companySystemRoutes from "./company-system-routes"

export default [
  ...authRoutes,
  ...baseRoutes,
  ...priceManagementRoutes,
  ...settingsRoutes,
  ...accountManagementRoutes,
  ...miscRoutes,
  ...planManagementRoutes,
  ...companySystemRoutes,
  ...superadminRoutes,
  ...otaAdminRoutes
]
