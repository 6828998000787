import axios from "axios"
import qs from "qs"

import { BASE_API_URL } from "@/config/constants"
import { requestInterceptor, responseInterceptor, errorInterceptor } from "./interceptors"
import { useTestingFeatures } from "./testing"

const client = axios.create({
  json: true,
  baseURL: BASE_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: "brackets" }),
  withCredentials: true
})

client.interceptors.request.use(requestInterceptor)
client.interceptors.response.use(responseInterceptor, errorInterceptor)

if (process.env.VUE_APP_ENV === "test") {
  useTestingFeatures(client)
}

export default client
