import { currentDateInTimeZone } from "./common"
import { startOfDay } from "date-fns"
import { store } from "@/config"
import { findIndex, get } from "lodash-es"
import { DISABLED, MANUAL_MODE, RATE_CHECK_MODE, AUTO_PRICE_MODE, AI_MODE, ON, CALCULATED } from "@/config/constants"
import { getModesWithFeatures } from "@/config/translations_helper"

export const startOfCurrentDate = startOfDay(currentDateInTimeZone())

export const currentMode = () => {
  return store.getters.currentOrganization?.price_management_mode
}

const isCurrentMode = mode => {
  return currentMode() === mode
}

export const isDisabledMode = () => {
  return isCurrentMode(DISABLED)
}

export const isManualMode = () => {
  return isCurrentMode(MANUAL_MODE)
}

export const isRateCheckModeMode = () => {
  return isCurrentMode(RATE_CHECK_MODE)
}

export const isAutoPriceMode = () => {
  return isCurrentMode(AUTO_PRICE_MODE)
}

export const isAIMode = () => {
  return isCurrentMode(AI_MODE)
}

export const isAdvancedMode = () => {
  return [AUTO_PRICE_MODE, AI_MODE].includes(currentMode())
}

export const isAutoSetPrices = () => {
  const autoUpdatePrice = get(store.getters.currentOrganization, "price_setting.auto_update_price")

  return autoUpdatePrice === ON
}

export const isAutoApplyingRecommendedPrices = () => {
  const preselectedPriceType = get(store.getters.currentOrganization, "price_setting.preselected_price_type")

  return preselectedPriceType === CALCULATED
}

export const currentModeIndex = i18n => {
  return findIndex(getModesWithFeatures(i18n), { id: currentMode() })
}

export const isNotAchievedMode = (i18n, id) => {
  const modeIndex = findIndex(getModesWithFeatures(i18n), { id })

  return modeIndex > currentModeIndex(i18n)
}
