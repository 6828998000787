import { transform, uniq, filter } from "lodash-es"
import Vue from "vue"
import Vuex from "vuex"

import { api } from "@/config"
import { DEFAULT_LAYOUT, SIMPLE_LAYOUT, AUTH_LAYOUT, TOYOTA_ORDER, NICOLAS_OTA_RAW_NAME } from "@/config/constants"
import { translateOtaName, translateOtaList } from "@/helpers/common"
import { in300DaysFromCurrentDate, in180DaysFromCurrentDate } from "@/helpers/dates"

Vue.use(Vuex)

const defaultState = () => ({
  currentUser: {},
  signOutConfirmations: {},
  // Loads only for admin on organization edit page
  allowedOtaList: [],
  organizationOtaList: [],
  layout: DEFAULT_LAYOUT,
  registeredModuleNames: [],
  pages: []
})

const getToyotaOrder = name => TOYOTA_ORDER[name] || TOYOTA_ORDER["others"]

export default new Vuex.Store({
  state: defaultState,

  mutations: {
    SET_LAYOUT(state, value) {
      state.layout = [DEFAULT_LAYOUT, SIMPLE_LAYOUT, AUTH_LAYOUT].includes(value) ? value : DEFAULT_LAYOUT
    },

    SET_CURRENT_USER(state, value) {
      state.currentUser = value || {}
    },

    SET_OTA_LIST(state, value) {
      state.allowedOtaList = value || []
    },

    SET_ORGANIZATION_OTA_LIST(state, value) {
      state.organizationOtaList = value || []
    },

    RESET_ORGANIZATION_OTA_LIST(state) {
      state.organizationOtaList = []
    },

    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },

    SET_SIGN_OUT_CONFIRMATION(state, value) {
      state.signOutConfirmations = { ...state.signOutConfirmations, ...value }
    },

    SET_REGISTERED_MODULE_NAMES(state, name) {
      const moduleNames = uniq([...state.registeredModuleNames, name])

      state.registeredModuleNames = moduleNames
    },

    SET_PAGES(state, value) {
      state.pages = value || []
    }
  },

  actions: {
    FETCH_CURRENT_USER({ commit }) {
      return api.get("/current_user").then(({ data: { data } }) => {
        const { organization } = data
        const otas = organization.toyota_organization
          ? organization.otas.sort((a, b) => getToyotaOrder(a.name) - getToyotaOrder(b.name))
          : organization.otas.sort((a, b) => a.id - b.id)

        const dataWithOrderedOtas = {
          ...data,
          organization: { ...data.organization, otas }
        }

        commit("SET_CURRENT_USER", dataWithOrderedOtas)
      })
    },

    FETCH_OTA_LIST({ commit, state }) {
      if (state.allowedOtaList.length) {
        return Promise.resolve()
      } else {
        api.get("/otas").then(({ data: { data } }) => {
          commit("SET_OTA_LIST", data)
        })
      }
    },

    async FETCH_ORGANIZATION_OTA_LIST({ commit, state }) {
      if (state.organizationOtaList.length === 0) {
        const {
          data: { data }
        } = await api.get("/organization_otas")
        commit("SET_ORGANIZATION_OTA_LIST", data)
      }
    },

    FETCH_PAGES({ commit, state }) {
      if (state.pages.length) {
        return Promise.resolve()
      } else {
        api.get("/pages").then(({ data: { data } }) => {
          commit("SET_PAGES", data)
        })
      }
    }
  },

  getters: {
    currentOrganization: ({ currentUser }) => currentUser.organization,

    isCompanySystemAvailable({ currentUser }) {
      return currentUser.organization.company_system_available
    },

    isNicolasEnabled({ currentUser }) {
      return currentUser.organization.nicolas
    },

    isToyotaOrganization: ({ currentUser }) => {
      return currentUser.organization.toyota_organization
    },

    isOrixOrganization: ({ currentUser }) => {
      return currentUser.organization.orix_organization
    },

    otaList: ({ allowedOtaList }, { currentOrganization }) => {
      return allowedOtaList.length ? allowedOtaList : currentOrganization?.otas || []
    },

    maxDate: (_, { isNicolasEnabled }) => {
      return isNicolasEnabled ? in180DaysFromCurrentDate : in300DaysFromCurrentDate
    },

    priceCalendarAllowedOtas: ({ organizationOtaList }) => {
      return filter(organizationOtaList, { plan_settings_allowed: true }).map(item => ({
        ...item,
        name: translateOtaName(item.name)
      }))
    },

    planSettingsOtas: ({ organizationOtaList }) => {
      return translateOtaList(organizationOtaList)
    },

    translatedOtaList: (_, { otaList }) => {
      return translateOtaList(otaList)
    },

    otasWithoutNicolas: (_, { translatedOtaList }) => {
      return translatedOtaList.filter(({ rawName }) => rawName !== NICOLAS_OTA_RAW_NAME)
    },

    translatedOtaNamesMapping: (state, { translatedOtaList }) => {
      return transform(translatedOtaList, (mapping, { id, name }) => (mapping[id] = name), {})
    }
  },

  modules: {}
})
