import { store } from "@/config"
import { PERMISSION_DENY } from "@/config/constants"

export const hasPermission = (permissionType, accessType, permissionsScope = store.state.currentUser.permissions) => {
  const targetPermission = permissionsScope[permissionType]

  return targetPermission === accessType || (accessType === PERMISSION_DENY && targetPermission === undefined)
}

export const isSuperadmin = () => !!store.state.currentUser.superadmin

export const isOrganizationAdmin = () => !!store.state.currentUser.organization_admin

export const isOtaAdmin = () => !!store.state.currentUser.ota_admin
