import Vue from "vue"

import VueCookie from "vue-cookie"
Vue.use(VueCookie)

import BootstrapVue from "bootstrap-vue"
Vue.use(BootstrapVue)

import ScrollSync from "vue-scroll-sync"
Vue.component("ScrollSync", ScrollSync)

Vue.config.productionTip = false
