import multiguard from "vue-router-multiguard"
import { authenticated, authorized, inventoryMode } from "@/config/router/guards"

export default [
  {
    path: "/ota-accounts",
    name: "OtaAccounts",
    component: () => import(/* webpackChunkName: "OtaAccounts" */ "@/pages/OtaAccounts"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "ota_setting",
      permissionType: "ota_setting"
    }
  },
  {
    path: "/car-class-matching",
    name: "CarClassMatching",
    component: () => import(/* webpackChunkName: "CarClassMatching" */ "@/pages/CarClassMatching"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "car_class_matching",
      permissionType: "car_class_matching"
    }
  },
  {
    path: "/shop-matching",
    name: "ShopMatching",
    component: () => import(/* webpackChunkName: "ShopMatching" */ "@/pages/ShopMatching"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "shop_matching",
      permissionType: "shop_matching"
    }
  },
  {
    path: "/inventory-group-matching",
    name: "InventoryGroupMatching",
    component: () => import(/* webpackChunkName: "InventoryGroupMatching" */ "@/pages/InventoryGroupMatching"),
    beforeEnter: multiguard([authenticated, authorized, inventoryMode({ shared: true })]),
    meta: {
      title: "inventory_group_matching",
      permissionType: "inventory_group_matching"
    }
  },
  {
    path: "/shops-sources-car-matching",
    name: "ShopsSourcesCarMatching",
    component: () => import(/* webpackChunkName: "ShopsSourcesCarMatching" */ "@/pages/ShopsSourcesCarMatching"),
    beforeEnter: multiguard([authenticated, authorized, inventoryMode({ shared: false })]),
    meta: {
      title: "shops_sources_car_matching",
      permissionType: "shops_sources_car_matching"
    }
  },
  {
    path: "/shops-car-class-matching",
    name: "ShopsCarClassMatching",
    component: () => import(/* webpackChunkName: "ShopsCarClassMatching" */ "@/pages/ShopsCarClassMatching"),
    beforeEnter: multiguard([authenticated, authorized, inventoryMode({ shared: false })]),
    meta: {
      title: "shops_car_class_matching",
      permissionType: "shops_car_class_matching"
    }
  },
  {
    path: "/inventory-groups-car-class-matching",
    name: "InventoryGroupsCarClassMatching",
    component: () =>
      import(/* webpackChunkName: "InventoryGroupsCarClassMatching" */ "@/pages/InventoryGroupsCarClassMatching"),
    beforeEnter: multiguard([authenticated, authorized, inventoryMode({ shared: true })]),
    meta: {
      title: "inventory_group_car_class_matching",
      permissionType: "inventory_group_car_class_matching"
    }
  },
  {
    path: "/inventory-notifications",
    name: "InventoryNotificationSettings",
    component: () =>
      import(/* webpackChunkName: "InventoryNotificationSettings" */ "@/pages/InventoryNotificationSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "inventory_notification_settings",
      permissionType: "inventory_notification_settings"
    }
  }
]
