import multiguard from "vue-router-multiguard"
import { authenticated, onlyOtaAdmin } from "@/config/router/guards"

export default [
  {
    path: "/ota-management/company-list",
    name: "OtaManagement/CompanyList",
    component: () => import(/* webpackChunkName: "OtaManagement/CompanyList" */ "@/pages/OtaManagement/CompanyList"),
    beforeEnter: multiguard([authenticated, onlyOtaAdmin]),
    meta: {
      title: "ota_management.company_list"
    }
  },
  {
    path: "/ota-management/plan-list",
    name: "OtaManagement/PlanList",
    component: () => import(/* webpackChunkName: "OtaManagement/PlanList" */ "@/pages/OtaManagement/PlanList"),
    beforeEnter: multiguard([authenticated, onlyOtaAdmin]),
    meta: {
      title: "ota_management.plan_list"
    }
  },
  {
    path: "/ota-management/booking-confirmation",
    name: "OtaManagement/BookingConfirmation",
    component: () =>
      import(/* webpackChunkName: "OtaManagement/BookingConfirmation" */ "@/pages/OtaManagement/BookingConfirmation"),
    beforeEnter: multiguard([authenticated, onlyOtaAdmin]),
    meta: {
      title: "ota_management.booking_confirmation"
    }
  }
]
