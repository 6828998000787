import Vue from "vue"
import VueI18n from "vue-i18n"
import axios from "axios"
import { showToast } from "@/helpers/toasts"
import { BASE_API_URL } from "@/config/constants"

Vue.use(VueI18n)

const locales = ["en", "ja"]
const locale = Vue.cookie.get("spa.locale") || process.env.VUE_APP_LOCALE || "ja"
const fallbackLocale = process.env.VUE_APP_FALLBACK_LOCALE || "ja"

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages: {}
})

i18n.setLocale = locale => {
  i18n.locale = locale
  Vue.cookie.set("spa.locale", locale)
}

i18n.getLocale = () => i18n.locale

axios.defaults.baseURL = BASE_API_URL

Promise.all(
  locales.map(locale => {
    const url = `locales/${locale}`
    return axios.get(url)
  })
)
  .then(responses => {
    responses.forEach(({ data }, index) => {
      const locale = locales[index]
      i18n.setLocaleMessage(locale, data.data)
    })
  })
  .catch(({ response }) => {
    showToast({ text: response.data.common })
  })

export default i18n
