// packages
// Imports for IE11 compatibility [START
import "core-js/stable"
import "regenerator-runtime/runtime"
import "element-closest-polyfill"
// This is marked as optional by vue-bootstrap. Probably will be required later.
// import "intersection-observer"
// END]

import Vue from "vue"
import App from "./App"
import { router, store, i18n, icons, googleMaps } from "./config"

import "./directives"
import "./assets/styles/common.sass"

if (process.env.VUE_APP_ENV === "development") {
  window._ = require("lodash-es")
}

new Vue({
  router,
  i18n,
  store,
  icons,
  googleMaps,

  beforeCreate: () => window.history.replaceState(null, null, window.location.href.replace(/\/$/, "")),
  render: h => h(App)
}).$mount("#app")
