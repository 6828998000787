import multiguard from "vue-router-multiguard"
import { authenticated, onlySuperadmin, onlyOrganizationAdmin } from "@/config/router/guards"

export default [
  {
    path: "/maestro/organization-list",
    name: "Maestro/OrganizationList",
    component: () => import(/* webpackChunkName: "Maestro/OrganizationList" */ "@/pages/Maestro/OrganizationList"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "organization_management.organization_list"
    }
  },
  {
    path: "/maestro/markups/company-list",
    name: "Maestro/Markups/CompanyList",
    component: () =>
      import(/* webpackChunkName: "Maestro/Markups/CompanyList" */ "@/pages/Maestro/Markups/CompanyList"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "ota_management.company_list"
    }
  },
  {
    path: "/maestro/markups/plan-list",
    name: "Maestro/Markups/PlanList",
    component: () => import(/* webpackChunkName: "Maestro/Markups/PlanList" */ "@/pages/Maestro/Markups/PlanList"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "ota_management.plan_list"
    }
  },
  {
    path: "/maestro/organization-account-list",
    name: "Maestro/OrganizationAccountList",
    component: () =>
      import(/* webpackChunkName: "Maestro/OrganizationAccountList" */ "@/pages/Maestro/AccountList/AccountsTable"),
    beforeEnter: multiguard([authenticated, onlyOrganizationAdmin]),
    meta: {
      title: "account_list",
      permissionType: "account_list"
    }
  },
  {
    path: "/maestro/account-list",
    name: "Maestro/AccountList",
    redirect: { name: "CarRental" },
    component: () => import(/* webpackChunkName: "Maestro/AccountList" */ "@/pages/Maestro/AccountList"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "account_list",
      permissionType: "account_list"
    },
    children: [
      {
        name: "CarRental",
        path: "car-rental",
        component: () =>
          import(/* webpackChunkName: "Maestro/AccountList/CarRental" */ "@/pages/Maestro/AccountList/AccountsTable"),
        meta: {
          title: "account_list"
        }
      },
      {
        name: "OtaAdmins",
        path: "ota-admins",
        component: () =>
          import(/* webpackChunkName: "Maestro/AccountList/OtaAdmins" */ "@/pages/Maestro/AccountList/AccountsTable"),
        meta: {
          title: "account_list"
        }
      }
    ]
  },
  {
    path: "/maestro/organizations-admin-list",
    name: "Maestro/OrganizationsAdminList",
    component: () => import(/* webpackChunkName: "Maestro/AccountList" */ "@/pages/Maestro/AccountList/AccountsTable"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "organizations_admin_list"
    }
  },
  {
    path: "/maestro/config-items",
    name: "Maestro/ConfigItemsList",
    component: () => import(/* webpackChunkName: "Maestro/ConfigItemsList" */ "@/pages/Maestro/ConfigItemsList"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "config_items_list"
    }
  },
  {
    path: "/maestro/translations",
    name: "Maestro/Translations",
    component: () => import(/* webpackChunkName: "Translations" */ "@/pages/Maestro/Translations"),
    beforeEnter: multiguard([authenticated, onlySuperadmin]),
    meta: {
      title: "translations",
      permissionType: "edit_translations"
    }
  }
]
