import multiguard from "vue-router-multiguard"
import { authenticated } from "@/config/router/guards"

export default [
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "NotFound" */ "@/pages/NotFound"),
    meta: { title: "not_found" }
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () => import(/* webpackChunkName: "Forbidden" */ "@/pages/Forbidden"),
    beforeEnter: multiguard([authenticated])
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ "@/pages/TermsAndConditions"),
    meta: { title: "terms_and_conditions" }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ "@/pages/PrivacyPolicy"),
    meta: { title: "privacy_policy" }
  },
  {
    path: "/revenue-analysis",
    name: "RevenueAnalysis",
    component: () => import(/* webpackChunkName: "RevenueAnalysis" */ "@/pages/RevenueAnalysis"),
    beforeEnter: multiguard([authenticated]),
    meta: {
      title: "revenue_analysis",
      permissionType: "account_list"
    }
  }
]
