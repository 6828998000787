import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

import { setLayout } from "@/config/router/guards"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: ({ hash }, _, savedPosition) => {
    if (savedPosition) return savedPosition

    if (hash) {
      return {
        selector: hash,
        offset: { x: 0, y: 0 }
      }
    }

    return { x: 0, y: 0 }
  }
})

router.beforeResolve(setLayout)

export default router
