import Vue from "vue"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import * as list from "@/config/icons/list"

Vue.component("FaIcon", FontAwesomeIcon)
Vue.config.productionTip = false

library.add(list)

export default library
