import multiguard from "vue-router-multiguard"
import { authenticated, authorized } from "@/config/router/guards"

export default [
  {
    path: "/company-system/booking-confirmation",
    name: "CompanySystem/BookingConfirmation",
    component: () =>
      import(/* webpackChunkName: "CompanySystem_BookingConfirmation" */ "@/pages/CompanySystem/BookingConfirmation"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "booking_confirmation",
      permissionType: "company_system"
    }
  },
  {
    path: "/company-system/rate-plan-settings",
    name: "CompanySystem/RatePlanSettings",
    redirect: { name: "RatePlan" },
    component: () =>
      import(/* webpackChunkName: "CompanySystem_RatePlanSettings" */ "@/pages/CompanySystem/RatePlanSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "rate_plan",
      permissionType: "company_system"
    },
    children: [
      {
        name: "RatePlan",
        path: "rate-plan",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_RatePlanSettings_RatePlan" */ "@/pages/CompanySystem/RatePlanSettings/RatePlan"
          ),
        meta: {
          title: "rate_plan",
          permissionType: "company_system"
        }
      },
      {
        name: "PriceTable",
        path: "price-table",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_RatePlanSettings_PriceTable" */ "@/pages/CompanySystem/RatePlanSettings/PriceTable"
          ),
        meta: {
          title: "rate_plan",
          permissionType: "company_system"
        }
      }
    ]
  },
  {
    path: "/company-system/car-class-settings",
    name: "CompanySystem/CarClassSettings",
    redirect: { name: "CarClass" },
    component: () =>
      import(/* webpackChunkName: "CompanySystem_CarClassSettings" */ "@/pages/CompanySystem/CarClassSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "car_class_settings",
      permissionType: "company_system"
    },
    children: [
      {
        name: "CarClass",
        path: "car-class",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_CarClassSettings_CarClass" */ "@/pages/CompanySystem/CarClassSettings/CarClass"
          ),
        meta: {
          title: "car_class_settings",
          permissionType: "company_system"
        }
      },
      {
        name: "CarModel",
        path: "car-model",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_CarClassSettings_CarModel" */ "@/pages/CompanySystem/CarClassSettings/CarModel"
          ),
        meta: {
          title: "car_class_settings",
          permissionType: "company_system"
        }
      }
    ]
  },
  {
    path: "/company-system/shops-settings",
    name: "CompanySystem/ShopsSettings",
    redirect: { name: "Shops" },
    component: () => import(/* webpackChunkName: "ShopsSettings" */ "@/pages/CompanySystem/ShopsSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "shops-settings",
      permissionType: "company_system" // TODO: change after integration
    },
    children: [
      {
        name: "Shops",
        path: "shops",
        component: () =>
          import(/* webpackChunkName: "ShopsSettings_Shops" */ "@/pages/CompanySystem/ShopsSettings/Shops"),
        meta: {
          title: "shops",
          permissionType: "company_system"
        }
      },
      {
        name: "InventoryGroups",
        path: "inventory-groups",
        component: () =>
          import(
            /* webpackChunkName: "ShopsSettings_InventoryGroups" */ "@/pages/CompanySystem/ShopsSettings/InventoryGroups"
          ),
        meta: {
          title: "inventory_groups",
          permissionType: "company_system" // TODO: change after integration
        }
      }
    ]
  },
  {
    path: "/company-system/basic-settings",
    name: "CompanySystem/BasicSettings",
    redirect: { name: "Options" },
    component: () =>
      import(/* webpackChunkName: "CompanySystem_BasicSettings" */ "@/pages/CompanySystem/BasicSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "basic_settings",
      permissionType: "company_system"
    },
    children: [
      {
        name: "TimeDivision",
        path: "time-division",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_BasicSettings_TimeDivision" */ "@/pages/CompanySystem/BasicSettings/TimeDivision"
          ),
        meta: {
          title: "basic_settings",
          permissionType: "company_system"
        }
      },
      {
        name: "Options",
        path: "options",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_BasicSettings_Options" */ "@/pages/CompanySystem/BasicSettings/Options"
          ),
        meta: {
          title: "basic_settings",
          permissionType: "company_system"
        }
      },
      {
        name: "DropOffFees",
        path: "drop-off-fees",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_BasicSettings_DropOffFees" */ "@/pages/CompanySystem/BasicSettings/DropOffFees"
          ),
        meta: {
          title: "basic_settings",
          permissionType: "company_system"
        }
      },
      {
        name: "CancellationFees",
        path: "cancellation-fees",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_BasicSettings_CancellationFees" */ "@/pages/CompanySystem/BasicSettings/CancellationFees"
          ),
        meta: {
          title: "basic_settings",
          permissionType: "company_system"
        }
      },
      {
        name: "About",
        path: "about",
        component: () =>
          import(
            /* webpackChunkName: "CompanySystem_BasicSettings_About" */ "@/pages/CompanySystem/BasicSettings/About"
          ),
        meta: {
          title: "basic_settings",
          permissionType: "company_system"
        }
      }
    ]
  }
]
